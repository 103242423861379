import { saveToken, loadToken } from "./localStorage";

const auth = {
  authentication: false,
  isAuthenticated(callback) {
    if (loadToken() != null) {
      this.authentication = true;
      setTimeout(callback, 300);
    } else {
      this.authentication = false;
    }
    return this.authentication;
  },
  signout(callback) {
    saveToken(null);
    this.authentication = false;
    setTimeout(callback, 300);
  },
  authenticate(token) {
    saveToken(token);
  }
};

export default auth;
