const changeLang = (NewLang = "es") => {
  try {
    const serializedLang = JSON.stringify(NewLang);
    localStorage.setItem("lang", serializedLang);
  } catch (err) {}
};

const readLang = () => {
  try {
    const serialized = localStorage.getItem("lang");
    if (serialized === null) {
      const serializedLang = JSON.stringify("es");
      localStorage.setItem("lang", serializedLang);
      return "es";
    }
    return JSON.parse(serialized);
  } catch (err) {
    const serializedLang = JSON.stringify("es");
    localStorage.setItem("lang", serializedLang);
    return "es";
  }
};

const islangitem = item => {
  try {
    const serialized = localStorage.getItem("lang");
    const lang = JSON.parse(serialized);
    const newitem = {};
    if (typeof item[lang] === "undefined") {
      newitem[lang] = item;
      return newitem;
    }
    newitem[lang] = item[lang];
    return newitem;
  } catch (err) {
    return item;
  }
};
const langElement = item => {
  if (item == null) return "";
  const actuallang = readLang();
  if (typeof item[actuallang] === "undefined") {
    return item["es"];
  } else {
    return item[actuallang];
  }
};
export { changeLang, readLang, islangitem, langElement };
