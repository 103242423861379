import { loadToken } from "./localStorage";
import config from "../config.js";
import { readLang } from "./languages.js";

const prepareHeader = options => {
  let header = config.headers;
  if (typeof options !== "undefined") {
    header["Content-Type"] = options["Content-Type"];
  }
  header.langid = readLang();
  const token = loadToken();
  if (token) {
    header.token = token;
  }
  return header;
};

export { prepareHeader };
