import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import config from '../../../config.js';
import auth from '../../../utils/authenticator';
import { prepareHeader } from '../../../utils/headerHandler';

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false
    };
  }

  loginauth = () => {
    auth.isAuthenticated(() => {
      this.setState({ redirectToReferrer: true });
    });
  };
  addToken = token => {
    this.setState({
      token
    });
    auth.authenticate(token);
  };

  usernameRef = React.createRef();
  passwordRef = React.createRef();

  Login = e => {
    e.preventDefault();
    let data = JSON.stringify({
      mobilePhone: this.usernameRef.current.value,
      accessCode: this.passwordRef.current.value
    });
    axios
      .post(`${config.DOMAIN}clients/login`, data, {
        headers: prepareHeader({ 'Content-Type': 'application/json' })
      })
      .then(res => {
        // agregarlo y enviarlo state
        if (
          !(res.data.user.role === 'ADMIN_ROLE' || res.data.user.role === 'EXTERNAL_ADMIN_ROLE')
        ) {
          Swal.fire('No logeado', 'Debes ser ADMIN_ROLE para loguear', 'error');
          return;
        }
        this.addToken(res.data.token);
        this.loginauth();
        this.setState({ redirectToReferrer: true });

        // resetear datos
        //e.currentTarget.reset();
      })
      .catch(error => {
        if (error && error.response && error.response.status) {
          if (error.response.status === 404) {
            Swal.fire('No logeado', error.response.statusText, 'error');
          }
          if (error.response.status === 400) {
            Swal.fire('No logeado', error.response.statusText, 'error');
          }
        }
      });
  };

  render() {
    const { redirectToReferrer } = this.state;
    if (redirectToReferrer === true) {
      return <Redirect to="/" />;
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.Login}>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Username"
                          autoComplete="username"
                          className="form-control"
                          innerRef={this.usernameRef}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          className="form-control"
                          innerRef={this.passwordRef}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4">
                            Login
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0">
                            Forgot password?
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
