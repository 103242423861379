const loadToken = () => {
  try {
    const serialized = localStorage.getItem("token");
    if (serialized === null) return undefined;

    return JSON.parse(serialized);
  } catch (err) {
    return undefined;
  }
};

const saveToken = token => {
  try {
    const serializedToken = JSON.stringify(token);
    localStorage.setItem("token", serializedToken);
  } catch (err) {}
};

const deleteToken = () => {
  try {
    localStorage.removeItem("token");
  } catch (err) {}
};

export { loadToken, saveToken, deleteToken };
