export default {
  DOMAIN:
    process.env.REACT_APP_PRODUCTION === "true"
      ? process.env.REACT_APP_API_URL_ADMIN_PRO
      : process.env.REACT_APP_API_URL_ADMIN,
  URLAMAZON: "https://content.leasysrenting.com",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded",
    apikey: "$2b$10$Cvbg9wi.0KWViN5PoeUPGezXddz9RKuTySKabjTfV0Uk7jPlzB2xS",
    domain: "localhost",
  },
};
